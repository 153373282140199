<template>
  <div>
    <template v-if="$route.path === '/manageTeacher'">
      <el-card class="box-card">
        <div class="item">
          <div style="height: 40px">
            <div class="head-left">
              <el-button v-if="showAddBtn" @click="addStaff" type="primary">
                添加账号
              </el-button>
              <span class="phone-txt">
                员工数量：{{ totals }}/{{ userInfo.teacherMaxNum }}
              </span>
              <el-button
                @click="$root.toggleUpdatePopup"
                type="text"
                v-if="userInfo.version_id != 4"
              >
                升级
              </el-button>
            </div>
            <div class="head-right">
              <el-form
                :inline="true"
                :model="formInline"
                class="form-inline"
                @submit.native.prevent
              >
                <el-form-item class="item1">
                  <el-select
                    v-model="formInline.jg_group_id"
                    @change="getTeacherList"
                  >
                    <el-option label="全部角色" :value="0"></el-option>
                    <el-option
                      v-for="item in authList"
                      :key="item.jg_group_id"
                      :label="item.name"
                      :value="item.jg_group_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item2">
                  <el-select v-model="formInline.type">
                    <el-option
                      v-for="item in selectCateGory"
                      :key="item.id"
                      :label="item.lable"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item3">
                  <el-input
                    v-model.trim="formInline.search"
                    :placeholder="placeholder"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <pagination2
            :option="formInline"
            url="/User/getTeacherList"
            @complete="getTeacherList"
          >
            <template v-slot:default>
              <el-table
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column prop="uname" label="名称">
                  <template slot-scope="scope">
                    <div class="userInfo">
                      <img class="left" :src="scope.row.uphoto" />
                      <div class="right">
                        <div class="t1">{{ scope.row.uname }}</div>
                        <div class="t2">
                          {{
                            scope.row.services_time
                              | formatTimeStamp('yyyy.MM.dd')
                          }}加入
                        </div>
                        <div v-if="scope.row.is_creater" style="font-size: 12px; color: #ff3535">
                          创建人
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="is_jigouadmin " label="角色">
                  <template slot-scope="scope">
                    <div v-for="item in scope.row.role" :key="item">
                      {{ item }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="umobile"
                  label="手机号"
                ></el-table-column>
                <el-table-column
                  prop="live_num"
                  label="直播次数"
                ></el-table-column>
                <el-table-column prop="regdate" label="最后登录">
                  <template slot-scope="scope">
                    <template
                      v-if="scope.row.lastdate && scope.row.lastdate > 0"
                    >
                      {{
                        scope.row.lastdate | formatTimeStamp('yyyy-MM-dd hh:mm')
                      }}
                    </template>
                    <template v-else>--</template>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="showEditBtn || showRemoveBtn"
                  label="操作"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="showEditBtn"
                      @click="handleClick(scope.row)"
                      type="text"
                      size="small"
                    >
                      编辑
                    </el-button>
                    <template v-if="showRemoveBtn">
                      <span
                        v-if="scope.row.uid != userInfo.uid"
                        style="
                          vertical-align: middle;
                          display: inline-block;
                          padding: 0px 10px;
                          padding-bottom: 3px;
                          color: #1b9d97;
                        "
                      >
                        |
                      </span>
                      <el-button
                        v-if="scope.row.uid != userInfo.uid"
                        @click="deletePeople(scope.row, scope.$index)"
                        type="text"
                        size="small"
                      >
                        移除
                      </el-button>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
        </div>
      </el-card>
      <el-dialog
        title="移除老师"
        width="422px"
        :visible.sync="deleteTeacher"
        custom-class="new-tag"
        :before-close="deleteTeacherClose"
      >
        <div style="margin: 20px 20px; margin-top: 0px">
          <p
            style="
              margin-bottom: 10px;
              font-size: 14px;
              color: rgba(102, 102, 102, 1);
            "
          >
            移除老师后，该账号将无法登录当前网校，是否确认移除？
          </p>
          <el-input
            v-model.trim="inputName"
            maxlength="4"
            show-word-limit
            placeholder="请输入“确认移除”"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteTeacherClose">取 消</el-button>
          <el-button
            :style="`opacity:${inputName === '确认移除' ? 1 : 0.5}`"
            type="primary"
            @click="teacherDelete"
          >
            确 定
          </el-button>
        </span>
      </el-dialog>
    </template>
    <router-view />
  </div>
</template>
<script>
import background from '@/mixin/background'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      formInline: {
        //下拉框以及输入框
        course: '',
        jg_group_id: 0,
        type: 1,
      },
      authList: [],
      selectCateGory: [
        { lable: '员工名称', id: 1, placeholder: '输入名称搜索' },
        { lable: '手机号', id: 2, placeholder: '输入手机号搜索' },
      ],
      jg_group_id: '',
      deleteTeacher: false,
      inputName: '',
      deletePeopleItem: [],
      jgInfo: {},

      tableData: [],

      totals: 0,
      // 移除所有私信好友
    }
  },

  computed: {
    ...mapState(['userInfo']),
    placeholder() {
      const val =
        this.selectCateGory.find((item) => item.id == this.formInline.type) ||
        []
      return val.placeholder
    },

    showRemoveBtn() {
      return this.$store.state.roots.includes(106)
    },

    showAddBtn() {
      return this.$store.state.roots.includes(104)
    },

    showEditBtn() {
      return this.$store.state.roots.includes(105)
    },
  },

  mixins: [background],

  created() {
    this.getAuthList()
  },

  methods: {
    handleClick(item) {
      this.$router.push({
        name: 'updataPeople',
        params: {
          data: _.merge({}, item),
        },
      })
    },

    deleteTeacherClose() {
      this.deleteTeacher = false
      this.inputName = ''
    },

    deletePeople(item, index) {
      const that = this
      that.deleteTeacher = true
      that.deletePeopleItem = _.assign({}, item, { index })
    },

    // 获取角色信息
    getAuthList() {
      this.$http({
        url: '/Auth/userRolesList',
        callback: ({ data }) => {
          this.authList = data
          this.jg_group_id = data[0].jg_group_id
        },
      })
    },

    // 获取老师列表回调函数
    getTeacherList({ data, allNum, count }) {
      this.tableData = data
      this.totals = this.totals
        ? this.totals
        : parseInt(allNum || count || this.totals)
    },

    teacherDelete() {
      const that = this
      if (that.inputName == '确认移除') {
        that.deleteTeacher = false
        that.$http({
          name: 'removeTeacher',
          url: 'Organ/removeTeacher',
          data: {
            type: 1,
            tid: that.deletePeopleItem.uid,
          },
          callback() {
            that.tableData.splice(that.deletePeopleItem.index, 1)
            that.totals -= 1
            that.inputName = ''
          },
        })
      }
    },

    addStaff() {
      if (this.totals >= this.userInfo.teacherMaxNum) {
        this.$root.prompt('老师人数已达上限')
      } else {
        this.$router.push({
          name: 'addTeacher',
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .form-inline {
  .el-input {
    width: 120px;
    height: 36px;
    .el-input__icon {
      line-height: 36px;
    }
  }
  .item2 {
    margin-right: 0;
    .el-input__inner {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .item3 {
    .el-input {
      width: 218px;
    }
    .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.box-card {
  background: rgba(255, 255, 255, 1);
  margin: 20px;
}
.el-button {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button.el-button--default:hover {
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
  color: #606266 !important;
}
.el-button.el-button--default {
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
  color: #606266 !important;
}
.el-button--text:hover {
  color: #1b9d97 !important;
  border: 0px !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.el-button--text {
  color: #1b9d97 !important;
  border: 0px !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.phone-txt {
  margin: 0px 10px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.head-left {
  float: left;
}
.head-right {
  height: 40px;
  float: right;
  margin-bottom: 30px;
}

.el-table.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition {
  margin: 30px auto;
}

.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #333;
}
.el-table .descending .sort-caret.descending {
  border-top-color: #333 !important;
}
.el-table::before {
  height: 0px;
}
.el-message-box .el-message-box__content {
  padding-top: 13px !important;
  padding-bottom: 0px !important;
}
.el-message-box__container {
  font-size: 14px;

  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #1b9d97;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #1b9d97;
  background: #1b9d97;
}
.el-radio__inner:hover {
  border-color: #1b9d97;
}
.copy-txt {
  margin-top: 51px;
  margin-bottom: 20px;
  font-size: 14px;
  height: 19px;

  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.copy-details {
  float: left;
  width: 580px;
  height: 91px;
  background: rgba(245, 245, 245, 1);
  border-radius: 2px;
  padding-top: 20px;
}
.copy-details p {
  padding-left: 20px;
  width: 500px;
  height: 48px;
  font-size: 14px;

  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}
.time-txt {
  font-size: 14px;

  color: rgba(153, 153, 153, 1);
  line-height: 19px;
}
.el-input__inner {
  font-size: 14px;

  color: rgba(155, 155, 155, 1);
}
/deep/ .new-tag .el-dialog__body {
  padding: 0px;
}
.el-dialog.new-tag {
  width: 422px;
  height: 204px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
}
.el-dialog.add-people {
  width: 800px;
  height: 518px;
  background: rgba(255, 255, 255, 1);
}
.userInfo {
  display: flex;
  align-items: center;
  .left {
    width: 34px;
    height: 34px;
    border-radius: 17px;
  }
  .right {
    margin-left: 10px;
    .t1 {
      font-size: 14px;
      line-height: 19px;
      color: rgba(51, 51, 51, 1);
    }
    .t2 {
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
